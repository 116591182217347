import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({layout, breakpoints, spacing}) => ({
  slideWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: spacing(12.5, 0),
    [breakpoints.down('sm')]: {
      height: 'auto',
      flexDirection: 'column-reverse',
      margin: spacing(6.9, 'auto'),
      maxWidth: layout.mainCarousel.slideWrapper.maxWidth,
    },
  },
  slideLeft: {
    width: '50%',
    paddingRight: '11%',
    [breakpoints.down('sm')]: {
      marginTop: spacing(2),
      width: '100%',
      paddingRight: 0,
      textAlign: 'center',
    },
  },
  autoWidth: {
    width: 'auto',
    paddingRight: 'inherit',
  },
  slideRight: {
    width: '50%',
    maxWidth: layout.mainCarousel.slideRight.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
      margin: spacing(0, 'auto', 2, 'auto'),
      display: 'inherit',
      flexDirection: 'inherit',
      justifyContent: 'inherit',
    },
  },
  slideDescriptionContainer: {
    [breakpoints.down('sm')]: {
      order: 2,
    },
  },
  slideImageContainer: {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      '& > video': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        position: 'sticky',
      },
    },
    [breakpoints.down('sm')]: {
      width: '100%',
      height: 'inherit',
      overflow: 'hidden',
      display: 'grid',
      maxWidth: layout.mainCarousel.image.maxWidth,
      margin: spacing(0, 'auto', 4, 'auto'),
      maxHeight: layout.mainCarousel.image.maxHeight,
      order: 1,
      '& > video': {
        objectFit: 'cover',
        width: '100%',
      },
    },
  },
  hasRoundCorner: {
    borderRadius: layout.mainCarousel.image.borderRadius,
    [breakpoints.down('sm')]: {
      borderRadius: layout.mainCarousel.image.borderRadiusMobile,
    },
  },
  hasSmallVerticalMargin: {
    margin: spacing(6, 0),
    [breakpoints.down('sm')]: {
      margin: spacing(3, 5, 'auto'),
    },
  },
  offsetBottom: {
    marginBottom: spacing(22.25),
  },
  isAlignCenter: {
    alignItems: 'center',
  },
  slideRightCta: {
    textAlign: 'center',
  },
}))

export default useStyles
