import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, breakpoints, spacing}) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: palette.background.default,
    boxShadow: '5px 5px 12px 0 rgba(51, 51, 51, 0.1)',
    borderRadius: spacing(0, 5),
    padding: '80px 100px',
    wordWrap: 'break-word',
    '& img': {
      width: 130,
      [breakpoints.down('sm')]: {
        maxWidth: 'inherit',
      },
    },
    [breakpoints.down('sm')]: {
      padding: 0,
      boxShadow: 'none',
      borderRadius: 0,
      alignItems: 'center',
      minWidth: '100%',
      '& div': {
        margin: 0,
      },
    },
  },
}))

export default useStyles
